// src/components/SEO.tsx
import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = () => (
  <Helmet>
    <title>Mock Interviews by MAANG Experts</title>
    <meta name="description" content="Prepare for your dream job with mock interviews by experts from FAAN companies." />
    <meta name="keywords" content="mock interviews, MAANG, software roles, job preparation, software developer, frontend developer, backend developer, fullstack developer, full stack developer" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </Helmet>
);

export default SEO;
