// src/components/Testimonials.tsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const TestimonialsContainer = styled.div`
  padding: 60px 20px;
  background: linear-gradient(135deg, #f0f4f8, #e9eff2);
  text-align: center;
`;

const Testimonial = styled(motion.div)`
  max-width: 700px;
  margin: 20px auto;
  padding: 30px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const TestimonialText = styled.p`
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 20px;
  font-style: italic;
`;

const TestimonialAuthor = styled.h4`
  font-size: 1.1rem;
  color: #007bff;
  margin: 0;
`;

const Testimonials = () => (
  <TestimonialsContainer>
    <Testimonial
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1 }}
    >
      <TestimonialText>
        "The mock interviews were fantastic! Helped me land my dream job at Salesforce."
      </TestimonialText>
      <TestimonialAuthor>- Akshitha</TestimonialAuthor>
    </Testimonial>
    <Testimonial
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 1, delay: 0.5 }}
    >
      <TestimonialText>
        "Great experience, insightful feedback. Highly recommend! Mock tests are really helpful get to know my knowledge which helped to focus on areas I need to improve"
      </TestimonialText>
      <TestimonialAuthor>- Dheeraj</TestimonialAuthor>
    </Testimonial>
  </TestimonialsContainer>
);

export default Testimonials;
