import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AboutContainer = styled.div`
  padding: 80px 20px;
  background-color: #f0f0f0;
  color: #333;
  text-align: center;
`;

const AboutContent = styled.div`
  max-width: 800px;
  margin: auto;
`;

const AboutTitle = styled(motion.h2)`
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #333;
`;

const AboutText = styled(motion.p)`
  font-size: 1.2em;
  line-height: 1.6;
  color: #333;
`;

const About = () => (
  <AboutContainer id="about">
    <AboutContent>
      <AboutTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        About Us
      </AboutTitle>
      <AboutText
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
      >
        We are a team of experienced software engineers from MAANG companies,
        committed to helping you excel in your technical interviews. Our mock
        interviews are designed to simulate real-world challenges and provide
        you with actionable feedback.
      </AboutText>
    </AboutContent>
  </AboutContainer>
);

export default About;
