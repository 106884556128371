import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/images/5f90da04-d56c-40bf-bda8-26dc08833d2f.jpeg';

const zoomIn = keyframes`
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
`;

const fadeInOut = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
`;

const typing = keyframes`
  from { width: 0 }
  to { width: 100% }
`;

const blinkCaret = keyframes`
  from, to { border-color: transparent }
  50% { border-color: orange; }
`;

const HeroContainer = styled.div`
  height: 100vh;
  background: url(${backgroundImage}) center/cover no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  // animation: ${zoomIn} 20s ease-in-out infinite alternate;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark overlay */
    z-index: 1;
  }

  @media (max-width: 768px) {
    background-position: center;
  }
`;

const HeroContent = styled.div`
  text-align: center;
  color: #fff;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 2;
  padding: 0 20px; /* Add padding to handle overflow */

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 0 10px; /* Reduce padding on smaller screens */
  }
`;

const HeroTitle = styled(motion.h1)`
  font-size: 3em;
  margin-bottom: 20px;
  color: #FFD700; /* Changed from orange to gold */

  @media (max-width: 768px) {
    font-size: 2em; /* Adjust font size for tablets */
  }

  @media (max-width: 480px) {
    font-size: 1.5em; /* Further adjust font size for mobile phones */
  }
`;

const HeroSubtitle = styled(motion.p)`
  font-size: 1.5em;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 1.2em; /* Adjust font size for tablets */
  }

  @media (max-width: 480px) {
    font-size: 1em; /* Further adjust font size for mobile phones */
  }
`;

const HeroButton = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  font-size: 1.2em;
  color: #fff;
  background-color: #28AFB0;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: #0F4343;
  }

  @media (max-width: 768px) {
    font-size: 1em; /* Adjust font size for tablets */
  }

  @media (max-width: 480px) {
    font-size: 0.9em; /* Further adjust font size for mobile phones */
  }
`;

const QuoteText = styled(motion.div)`
  font-size: 1.5em;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  overflow: hidden;
  white-space: nowrap;
  letter-spacing: 0.15em;
  
  @media (min-width: 769px) {
    white-space: nowrap;
    border-right: 0.15em solid orange; /* Blinking cursor effect for desktop */
    animation: 
      ${typing} 3.5s steps(40, end), /* Typing effect duration */
      ${blinkCaret} 0.75s step-end infinite; /* Blinking cursor */
  }

  @media (max-width: 768px) {
    white-space: wrap;
    animation: 
      ${fadeInOut} 5s ease-in-out infinite; /* Fade effect for mobile */
  }
`;

const quotes = [
  "Prepare, Practice, Perform – Get Hired!",
  "Boost your confidence with expert guidance.",
  "Master the art of technical interviews.",
  "Step up your game with personalized feedback.",
  "Your dream job is just one interview away.",
];

const Hero = () => {
  const [currentQuote, setCurrentQuote] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentQuote((prev) => (prev + 1) % quotes.length);
    }, 5000); // 5 seconds per quote

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroContainer>
      <HeroContent>
        <HeroTitle
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          Ace Your Next Technical Interview
        </HeroTitle>
        <QuoteText
          key={currentQuote}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          {quotes[currentQuote]}
        </QuoteText>
        <HeroSubtitle
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.5 }}
        >
          Get personalized feedback from MAANG company experts.
        </HeroSubtitle>
        <HeroButton
          href="#contact"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          Get Started
        </HeroButton>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
