import React, { useState } from 'react';
import styled from 'styled-components';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

const FAQsContainer = styled.div`
  padding: 50px 0;
  background-color: #eef2f3;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FAQCard = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 15px 0;
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  
  &:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
`;

const FAQHeader = styled.div`
  background-color: #00796b;
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  
  &:hover {
    background-color: #004d40;
  }
`;

const FAQContent = styled.div`
  padding: 15px;
  background-color: #f9f9f9;
  color: #555;
  line-height: 1.6;
`;

const ExpandableSection = styled.div`
  width: 100%;
  max-width: 800px;
  background: white;
  border-radius: 8px;
  margin: 15px 0;
`;

const SectionHeader = styled.div`
  background-color: #004d40;
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  display: flex;
  justify-content: space-between;
  
  &:hover {
    background-color: #00332a;
  }
`;

const FAQs = () => {
  const [openSection, setOpenSection] = useState<number | null>(null);
  const [openFAQ, setOpenFAQ] = useState<number | null>(null);

  const toggleSection = (index: number) => {
    setOpenSection(prev => (prev === index ? null : index));
    setOpenFAQ(null); // Close any open FAQ when switching sections
  };

  const toggleFAQ = (index: number) => {
    setOpenFAQ(prev => (prev === index ? null : index));
  };

  return (
    <FAQsContainer>
      <h2>Frequently Asked Questions</h2>

      {/* Section 1 */}
      <ExpandableSection>
        <SectionHeader onClick={() => toggleSection(0)}>
          General Questions
          {openSection === 0 ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </SectionHeader>
        {openSection === 0 && (
          <>
            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(1)}>
                How does your mock interview service work?
                {openFAQ === 1 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 1 && (
                <FAQContent>
                  Our service connects you with experienced interviewers from top tech companies. You schedule a mock interview, receive feedback, and get detailed reports to help you improve your performance.
                </FAQContent>
              )}
            </FAQCard>

            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(2)}>
                What types of interviews do you offer?
                {openFAQ === 2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 2 && (
                <FAQContent>
                  We offer technical mock interviews, including coding challenges, system design, and behavioral interviews, tailored to the roles you are preparing for.
                </FAQContent>
              )}
            </FAQCard>

            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(3)}>
                How can I book a mock interview?
                {openFAQ === 3 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 3 && (
                <FAQContent>
                  You can book a mock interview through our website. Choose a plan, select a slot, and provide your details. You’ll receive a confirmation email with all the necessary information.
                </FAQContent>
              )}
            </FAQCard>
          </>
        )}
      </ExpandableSection>

      {/* Section 2 */}
      <ExpandableSection>
        <SectionHeader onClick={() => toggleSection(1)}>
          Policies and Procedures
          {openSection === 1 ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </SectionHeader>
        {openSection === 1 && (
          <>
            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(4)}>
                What happens if I need to reschedule or cancel my interview?
                {openFAQ === 4 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 4 && (
                <FAQContent>
                  You can reschedule or cancel your interview by contacting us at least 24 hours in advance. Please refer to our cancellation policy for more details.
                </FAQContent>
              )}
            </FAQCard>

            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(5)}>
                What should I do if I experience technical issues during the interview?
                {openFAQ === 5 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 5 && (
                <FAQContent>
                  If you encounter any technical issues, please contact our support team immediately. We’ll do our best to resolve the issue or reschedule your interview if needed.
                </FAQContent>
              )}
            </FAQCard>
          </>
        )}
      </ExpandableSection>

      {/* Section 3 */}
      <ExpandableSection>
        <SectionHeader onClick={() => toggleSection(2)}>
          Additional Information
          {openSection === 2 ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </SectionHeader>
        {openSection === 2 && (
          <>
            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(6)}>
                How do I prepare for my mock interview?
                {openFAQ === 6 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 6 && (
                <FAQContent>
                  We provide guidelines and resources to help you prepare. Make sure to review the job role and practice common interview questions related to your field.
                </FAQContent>
              )}
            </FAQCard>

            <FAQCard>
              <FAQHeader onClick={() => toggleFAQ(7)}>
                What kind of feedback will I receive after the interview?
                {openFAQ === 7 ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </FAQHeader>
              {openFAQ === 7 && (
                <FAQContent>
                  You will receive detailed feedback on your performance, including strengths, areas for improvement, and suggestions to enhance your skills.
                </FAQContent>
              )}
            </FAQCard>
          </>
        )}
      </ExpandableSection>
    </FAQsContainer>
  );
};

export default FAQs;
