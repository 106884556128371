// src/components/Services.tsx
import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const ServicesContainer = styled.div`
  padding: 80px 20px;
  // background-color: #fff;
  background: linear-gradient(135deg, #2c3e50, #34495e);
`;

const ServicesContent = styled.div`
  max-width: 1200px;
  margin: auto;
  text-align: center;
`;

const ServicesTitle = styled(motion.h2)`
  font-size: 2.5em;
  color: #ecf0f1;
  margin-bottom: 20px;
`;

const ServicesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ServiceCard = styled(motion.div)`
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ServiceTitle = styled.h3`
  font-size: 1.5em;
  color: #333;
  margin-bottom: 10px;
`;

const ServiceDescription = styled.p`
  font-size: 1em;
  color: #555;
`;

const Services = () => (
  <ServicesContainer id="services">
    <ServicesContent>
      <ServicesTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        Our Services
      </ServicesTitle>
      <ServicesGrid>
        <ServiceCard
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <ServiceTitle>Mock Interviews</ServiceTitle>
          <ServiceDescription>
            Simulate a real technical interview with MAANG company engineers.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <ServiceTitle>Resume Review</ServiceTitle>
          <ServiceDescription>
            Get personalized feedback on your resume to make it stand out.
          </ServiceDescription>
        </ServiceCard>
        <ServiceCard
          initial={{ opacity: 0, scale: 0.8 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.7 }}
        >
          <ServiceTitle>Career Advice</ServiceTitle>
          <ServiceDescription>
            Receive guidance on career growth and job search strategies.
          </ServiceDescription>
        </ServiceCard>
      </ServicesGrid>
    </ServicesContent>
  </ServicesContainer>
);

export default Services;
