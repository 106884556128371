import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  background: #1e1e1e;
  color: #ecf0f1;
  padding: 20px 0;
  text-align: center;
`;

const FooterText = styled.p`
  font-size: 1em;
  margin: 0;
`;

const Footer = () => (
  <FooterContainer>
    <FooterText>&copy; 2024 MockInterviews. All Rights Reserved.</FooterText>
  </FooterContainer>
);

export default Footer;
