import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const FormContainer = styled.div`
  background: linear-gradient(135deg, #2c3e50, #34495e);
  padding: 80px 20px;
  text-align: center;
  color: #ecf0f1;
  width: 100%;
  box-sizing: border-box; /* Ensure padding is included in width */
`;

const FormTitle = styled(motion.h2)`
  font-size: 2.5em;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2em; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 1.5em; /* Further adjust font size for very small screens */
  }
`;

const Form = styled.form`
  max-width: 600px;
  margin: auto;
  width: 100%;
`;

const Input = styled(motion.input)`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background: #34495e;
  color: #ecf0f1;
  font-size: 1em;
  box-sizing: border-box; /* Ensure padding is included in width */

  @media (max-width: 768px) {
    padding: 12px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.9em; /* Adjust font size for very small screens */
  }
`;

const TextArea = styled(motion.textarea)`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background: #34495e;
  color: #ecf0f1;
  font-size: 1em;
  resize: none;
  box-sizing: border-box; /* Ensure padding is included in width */

  @media (max-width: 768px) {
    padding: 12px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.9em; /* Adjust font size for very small screens */
  }
`;

const Select = styled(motion.select)`
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #bdc3c7;
  background: #34495e;
  color: #ecf0f1;
  font-size: 1em;
  box-sizing: border-box; /* Ensure padding is included in width */

  option {
    background: #34495e;
    color: #ecf0f1;
  }

  @media (max-width: 768px) {
    padding: 12px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    font-size: 0.9em; /* Adjust font size for very small screens */
  }
`;

const SubmitButton = styled(motion.button)`
  background-color: #2980b9;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  box-sizing: border-box; /* Ensure padding is included in width */

  &:hover {
    background-color: #3498db;
  }

  @media (max-width: 768px) {
    padding: 12px 25px; /* Adjust padding for smaller screens */
    font-size: 1em; /* Adjust font size for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 10px 20px; /* Further adjust padding for very small screens */
    font-size: 0.9em; /* Further adjust font size for very small screens */
  }
`;

const ContactForm: React.FC = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    mobile: '',
    plan: 'basic', // Default value
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = (): boolean => {
    const { name, email, message, mobile, plan } = formData;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^[0-9]{10}$/;

    if (!name.trim()) {
      toast.error('Name is required.');
      return false;
    }

    if (!emailRegex.test(email.trim())) {
      toast.error('Please enter a valid email address.');
      return false;
    }

    if (!mobileRegex.test(mobile.trim())) {
      toast.error('Please enter a valid mobile number (10 digits).');
      return false;
    }

    if (!message.trim()) {
      toast.error('Message is required.');
      return false;
    }

    if (!plan) {
      toast.error('Please select a plan.');
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    // Show a buffer and success message
    toast.info('Submitting your request...', { autoClose: 1000 });

    try {
      const response = await fetch('https://us-central1-mockinterview-571e4.cloudfunctions.net/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
        toast.success('Contact saved successfully.');
        setFormData({
          name: '',
          email: '',
          message: '',
          mobile: '',
          plan: 'basic',
        });

    } catch (error) {
      console.error('Error submitting form:', error);
      toast.error('There was an error submitting your message. Please try again.');
    }
  };

  return (
    <FormContainer id="contact">
      <FormTitle
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.6 }}
      >
        Contact Us
      </FormTitle>
      <Form>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          value={formData.name}
          onChange={handleChange}
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.2 }}
        />
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          value={formData.email}
          onChange={handleChange}
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
        />
        <Input
          type="text"
          name="mobile"
          placeholder="Your Mobile Number"
          value={formData.mobile}
          onChange={handleChange}
          initial={{ opacity: 0, x: 20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.4 }}
        />
        <Select
          name="plan"
          value={formData.plan}
          onChange={handleChange}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <option value="free">Free Plan</option>
          <option value="basic">Basic Plan</option>
          <option value="standard">Standard Plan</option>
          <option value="premium">Premium Plan</option>
        </Select>
        <TextArea
          name="message"
          placeholder="Your Message"
          rows={5}
          value={formData.message}
          onChange={handleChange}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6, delay: 0.6 }}
        />
        <SubmitButton
          type="submit"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.2 }}
          onClick={handleSubmit}
        >
          Send Message
        </SubmitButton>
      </Form>
      <ToastContainer />
    </FormContainer>
  );
};

export default ContactForm;
