// src/pages/Home.tsx
import React from 'react';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import About from '../components/About';
import Services from '../components/Services';
import Pricing from '../components/Pricing';
import Testimonials from '../components/Testimonials';
import FAQs from '../components/FAQs';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Home = () => (
  <>
  <ToastContainer />
    <SEO />
    <Hero />
    <About />
    <Services />
    <Testimonials />
    <Pricing />
    <FAQs />
    <ContactForm />
    <Footer />
  </>
);

export default Home;
