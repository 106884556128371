import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const PricingContainer = styled.div`
  padding: 50px 20px;
  background: linear-gradient(135deg, #28AFB0, #A0D6D4);
  text-align: center;
`;

const Title = styled.h2`
  color: #333;
  margin-bottom: 40px;
  font-size: 2.5rem;
`;

const Plan = styled(motion.div)`
  display: inline-block;
  padding: 30px;
  margin: 20px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  max-width: 320px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
`;

const PlanTitle = styled.h3`
  color: #007bff;
  font-size: 1.8rem;
  margin-bottom: 10px;
`;

const PlanPrice = styled.p`
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 20px;
`;

const PlanDetails = styled.ul`
  list-style: none;
  padding: 0;
  color: #555;
  margin-bottom: 20px;

  & > li {
    margin-bottom: 10px;
  }
`;

const PlanButton = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }
`;

const Pricing = () => (
  <PricingContainer>
    <Title>Our Pricing</Title>
    <Plan
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5 }}
    >
      <PlanTitle>Free</PlanTitle>
      <PlanPrice>₹0</PlanPrice>
      <PlanDetails>
        <li>1 Technical Interview</li>
        <li>Basic Feedback Report</li>
        <li>Limited Email Support</li>
      </PlanDetails>
      {/* <PlanButton href="#">Select Free</PlanButton> */}
    </Plan>
    <Plan
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      <PlanTitle>Basic</PlanTitle>
      <PlanPrice>₹2000</PlanPrice>
      <PlanDetails>
        <li>2 Technical Interviews</li>
        <li>1 Month Validity</li>
        <li>Weekly Assignments & Reports</li>
        <li>Basic Feedback Report</li>
        <li>Email Support</li>
      </PlanDetails>
      {/* <PlanButton href="#">Select Basic</PlanButton> */}
    </Plan>
    <Plan
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.6 }}
    >
      <PlanTitle>Standard</PlanTitle>
      <PlanPrice>₹5000</PlanPrice>
      <PlanDetails>
        <li>5 Technical Interviews</li>
        <li>3 Months Validity</li>
        <li>Weekly Assignments & Reports</li>
        <li>Detailed Feedback Report</li>
        <li>Email & Chat Support</li>
        <li>Access to Interview Resources</li>
        <li>Referral to 1 company</li>
      </PlanDetails>
      {/* <PlanButton href="#">Select Standard</PlanButton> */}
    </Plan>
    <Plan
      initial={{ scale: 0.8, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.9 }}
    >
      <PlanTitle>Premium</PlanTitle>
      <PlanPrice>₹8000</PlanPrice>
      <PlanDetails>
        <li>10 Technical Interviews</li>
        <li>6 Months Validity</li>
        <li>Weekly Assignments & Reports</li>
        <li>In-Depth Feedback & Improvement Plan</li>
        <li>Priority Email & Chat Support</li>
        <li>Access to Premium Interview Resources</li>
        <li>Personalized Interview Coaching</li>
        <li>Referral to 3 companies and job support</li>
      </PlanDetails>
      {/* <PlanButton href="#">Select Premium</PlanButton> */}
    </Plan>
  </PricingContainer>
);

export default Pricing;
